import { gsap } from "gsap";

export default class Floating {
    selector: string;
    target: HTMLElement;
    options: {
        [random: string]: (d?: number) => number;
    }

    constructor(selector: string) {
        this.selector = selector;
        this.target = document.querySelector(selector);
        this.options = {
            randomX: this.random(5, 10),
            randomY: this.random(10, 15),
            randomDelay: this.random(0, 1),
            randomTime: this.random(3, 5),
            randomTime2: this.random(5, 10),
            randomAngle: this.random(8, 12)
        }
        this.init()
    }

    init() {
        let { randomX, randomAngle } = this.options;
        gsap.set(this.selector, {
            x: randomX(-1),
            y: randomX(1),
            rotation: randomAngle(-1)
        });

        this.moveX(this.selector, 1);
        this.moveY(this.selector, -1);
        this.rotate(this.selector, 1);
    }

    rotate(target: any, direction: any) {
        let { randomTime2, randomAngle } = this.options;
        gsap.to(target, {
            duration: randomTime2(),
            rotation: randomAngle(direction),
            ease: "sine.inOut",
            onComplete: (t, d) => this.rotate(t, d),
            onCompleteParams: [target, direction * -1]
        });
    }

    moveX(target: any, direction: any) {
        let { randomTime, randomX } = this.options;
        gsap.to(target, {
            duration: randomTime(),
            x: randomX(direction),
            ease: "sine.inOut",
            onComplete: (t, d) => this.moveX(t, d),
            onCompleteParams: [target, direction * -1]
        });
    }

    moveY(target: any, direction: any) {
        let { randomTime, randomY } = this.options;
        gsap.to(target, {
            duration: randomTime(),
            y: randomY(direction),
            ease: "sine.inOut",
            onComplete: (t, d) => this.moveY(t, d),
            onCompleteParams: [target, direction * -1]
        });
    }

    random(min: number, max: number) {
        const delta = max - min;
        return (direction = 1) => (min + delta * Math.random()) * direction;
    }
}