// Modules
import SplitText from "../components/splitText";
import { fixedSEO } from "../services/general";

// Libraries
import * as YouTubeIframeLoader from "youtube-iframe";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import Swiper, { Navigation } from 'swiper';
let pokerSwiper: Swiper;
let featureSwiper: Swiper;
let playerVideos: any = [];
let currentPlayer: any;


export const page = async () => {
  fixedSEO()

  const mmObj = window.matchMedia("(min-width: 640px)")
  checkSizeDevice(mmObj);
  mmObj.addListener(checkSizeDevice);

  // Set Initial Value
  gsap.set('.pf-pura-malta__title, .pf-pura-malta__desc', { opacity: 0, translateX: -20 })
  gsap.set('.pf-best-beer', { opacity: 0, translateX: 20 })

  // Create Video Swiper
  new Swiper('#videoSwiper', {
    modules: [Navigation],
    loop: true,
    centeredSlides: true,
    slidesPerView: "auto",
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    on: {
      afterInit: (e: any) => {
        setVideoController()
        loadSwiperVideo()
      },
      activeIndexChange: (e: any) => {
        let wrapperVideo = e.slides[e.activeIndex].querySelector('.pf-video-poker')
        let videoSlide = wrapperVideo.querySelector('.pf-video-poker__video')
        let videoId = wrapperVideo.dataset.video

        // PAUSA EL VIDEO ANTERIOR
        let previousVideo = playerVideos.find((v: any) => v.swiperIndex == e.previousIndex)
        if (previousVideo) previousVideo.player.stopVideo()

        // QUITA EL ID DEL VIDEO YA CARGADO
        let activeVideo = document.querySelector('#videoSwiper #videoPoker')
        activeVideo && activeVideo.removeAttribute('id')

        // ASIGNA EL ID AL NUEVO VIDEO
        if (videoSlide && videoSlide.tagName != 'IFRAME') {
          videoSlide.id = 'videoPoker'
          YouTubeIframeLoader.load(function (YT: any) {
            loadYoutube(YT, e, videoId)
          });
        }

        let currentSlide = playerVideos.find((v: any) => v.swiperIndex == e.activeIndex)
        currentPlayer = currentSlide && currentSlide.player
      },
    }
  })

  // Create Image Swiper
  new Swiper('#imageSwiper', {
    modules: [Navigation],
    centeredSlides: true,
    slidesPerView: "auto",
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  })

  loadFaqs()

  if (!localStorage.getItem('ageGateValidate')) return ObserveAgegate()
  loadAnimation()
}

const loadYoutube = (YT: any, e: any, videoId: string) => {
  const options = {
    threshold: 1.0
  }

  let target = document.querySelector('#videoSwiper');
  let observer = new IntersectionObserver((entries: any, observer: any) => {
    entries.forEach((entry: any) => {
      if (entry.isIntersecting) {     
        let nwPlayer = new YT.Player(`videoPoker`, {
          height: '390',
          width: '640',
          videoId: videoId,
          events: {
            'onReady': (e: any) => {
              e.target.setVolume(30)
              currentPlayer = nwPlayer
            }
          },
          playerVars: { 'controls': 0, 'rel': 0, 'disablekb': 0, 'showinfo': 0, 'enablejsapi': 1, 'origin': window.location.origin },
        });
        if (!playerVideos.some((v: any) => v.swiperIndex == e.activeIndex)) playerVideos.push({ swiperIndex: e.activeIndex, player: nwPlayer })
        observer.unobserve(entry.target)
      }
    })
  }, options);

  if (target) observer.observe(target);
}

// OBSERVER AGEGATE
const ObserveAgegate = () => {
  let target = document.body;
  let config = { attributes: true, childList: true, characterData: true };
  let mObserver = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation: any) {
      if (mutation.type == 'attributes' && !mutation.target.classList.contains('agegate-visible')) {
        loadAnimation()
        mObserver.disconnect()
      }
    });
  });
  mObserver.observe(target, config);
}

// LOAD GSAP ANIMATION
const loadAnimation = () => {
  pokerSwiper && pokerSwiper.update()

  // Vars
  const tl = gsap.timeline()
  let pokerCards = gsap.utils.toArray('.pf-bottle__list .pf-bottle__list-item')
  let toTopTransition = '.pf-bottle__img, .pf-best-section__desc, .pf-moments__desc, .pf-poker-separator'
  let loopSwipers = [
    { wrapperId: 'pokerSwiper', classItem: 'pf-feature-poker-card' },
    { wrapperId: 'videoSwiper', classItem: 'pf-video-poker' },
    { wrapperId: 'imageSwiper', classItem: 'pf-moment-slide__image' },
  ]

  // Spliting Titles
  new SplitText('.pf-bottle__title, .pf-videos__title, .pf-best-section__title, .pf-moments__title, .pf-questions__title')

  gsap.set(`${toTopTransition}, #pokerSwiper .swiper-slide .pf-feature-poker-card, .pf-video-poker, .pf-moment-slide__image, .pf-questions__item`, { opacity: 0, translateY: 20 })
  pokerCards.forEach((card: any, index: number) => gsap.set(card, { opacity: 0, translateX: [0, 2].includes(index) ? -20 : 20 }))

  // Animating
  setTimeout(() => {
    tl.to('.pf-pura-malta__title', { duration: 0.8, opacity: 1, translateX: 0 }, 't1')
    tl.to('.pf-pura-malta__desc', { duration: 0.8, opacity: 1, translateX: 0, delay: 0.5 }, 't1')

    gsap.utils.toArray(toTopTransition).forEach((element: any) => {
      gsap.to(element, {
        scrollTrigger: { trigger: element, start: 'top bottom-=50px' },
        duration: 0.8, opacity: 1, translateY: 0
      })
    })

    pokerCards.forEach((card: any) => {
      gsap.to(card, {
        scrollTrigger: { trigger: card, start: 'top bottom-=50px' },
        duration: 0.8, opacity: 1, translateX: 0
      })
    })

    loopSwipers.forEach(({ wrapperId, classItem }: any) => {
      gsap.to(`.swiper-slide-active .${classItem}`, {
        scrollTrigger: { trigger: `#${wrapperId}`, start: 'top bottom-=50px' },
        duration: 0.6, opacity: 1, translateY: 0
      })
        .then(() => {
          gsap.to(`.swiper-slide .${classItem}`, { duration: 0.8, opacity: 1, translateY: 0 })
        })
    })

    gsap.to('.pf-best-beer', {
      scrollTrigger: { trigger: '.pf-best-beer', start: 'top bottom-=50px' },
      duration: 0.8, opacity: 1, translateX: 0, stagger: 0.3
    })

    gsap.to('.pf-questions__item', {
      scrollTrigger: { trigger: '.pf-questions__item', start: 'top bottom-=50px' },
      duration: 0.8, opacity: 1, translateY: 0, stagger: 0.3
    })
  }, 500)
}

// LOAD IMAGES INTO VIDEO SWIPER
const loadSwiperVideo = () => {
  let pokerVideos: any = document.querySelectorAll('.pf-video-poker')
  for (let i = 0; i < pokerVideos.length; i++) {
    const image: HTMLImageElement = pokerVideos[i].querySelector('.pf-video-poker__image');
    const newImg = new Image;
    newImg.onload = () => image.src = newImg.src;
    newImg.src = `https://img.youtube.com/vi/${pokerVideos[i].dataset.video}/hq720.jpg`
  }
}

// SET CLICK EVENT INTO VIDEO SWIPER
const setVideoController = () => {
  let containerVideoSwiper: any = document.querySelector('#videoSwiper')
  containerVideoSwiper.addEventListener('click', (e: any) => {
    if (e.target.classList.contains('pf-video-poker__image')) {
      switch (currentPlayer.getPlayerState()) {
        case 1:
          currentPlayer.pauseVideo();
          break;

        default:
          currentPlayer.playVideo()
          break;
      }
    }
  })
}

// SET FAQS FUNCTIONALITY
const loadFaqs = () => {
  let questionContainer = document.querySelector(".pf-questions__body");
  questionContainer.addEventListener('click', (e: any) => {
    if (e.target.closest('.accordion')) {
      let accordion = e.target.closest('.accordion');
      let panel = accordion.nextElementSibling;
      let paragraph = panel.querySelector('p');
      accordion.classList.toggle("active");
      panel.classList.toggle("active")
      panel.style.maxHeight = panel.classList.contains("active") ? `${paragraph.scrollHeight}px` : '0px'
    }
  })
}

// CHECK DEVICE TO CREATE OR REMOVE MOBILE SWIPERS 
const checkSizeDevice = (x: any) => {
  if (x.matches) {
    if (pokerSwiper) !Array.isArray(pokerSwiper) ? pokerSwiper.destroy() : Array.from(pokerSwiper).forEach((swip: any) => swip.destroy());
    if (featureSwiper) !Array.isArray(featureSwiper) ? featureSwiper.destroy() : Array.from(featureSwiper).forEach((swip: any) => swip.destroy());
  } else {
    pokerSwiper = new Swiper('#pokerSwiper', {
      modules: [Navigation],
      centeredSlides: true,
      slidesPerView: "auto",
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
    featureSwiper = new Swiper('.featureSwiper', {
      modules: [Navigation],
      centeredSlides: true,
      slidesPerView: "auto",
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
  }
}