import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// Modules
import Floating from "../components/floating";
import { fixedSEO } from "../services/general";

// Libraries
import * as YouTubeIframeLoader from "youtube-iframe";

export const page = async () => {
    setClassBody()
    fixedSEO();
    loadVideoById();
    loadAnimation()
    new Floating('.pf-meet-pura-malta__bottle')
}

const loadVideoById = () => {
    const videoContainer: HTMLElement = document.querySelector('#landingVideo');
    const videoImage: HTMLImageElement = document.querySelector('#landingVideo .pf-meet-pura-malta__video-image');
    const videoSource: HTMLElement = document.querySelector('#landingVideo .pf-meet-pura-malta__video-source');

    if (videoContainer && videoImage) videoImage.src = `https://img.youtube.com/vi/${videoContainer.dataset.video}/hq720.jpg`
    if (videoContainer && videoSource) {
        YouTubeIframeLoader.load(function (YT: any) {
            new YT.Player(`landingVideoPoker`, {
                height: '390',
                width: '640',
                videoId: videoContainer.dataset.video,
                events: {
                    'onReady': (e: any) => {
                        e.target.setVolume(30)
                    }
                },
                playerVars: { 'rel': 0, 'enablejsapi': 1, 'origin': window.location.origin },
            });
        });
    }
}

const setClassBody = () => {
    document.body.classList.add('landing-pura-malta')
}

const loadAnimation = () => {
    const tl = gsap.timeline()

    // BANNER
    tl.to('.pf-banner__image', { duration: 0.8, opacity: 1 }, 'f1')
    tl.to('.pf-banner__tapa', { duration: 0.8, opacity: 1 }, 'f1')

    tl.to('.pf-home__title', { duration: 0.8, opacity: 1, translateX: 0 }, 'f2')
    tl.to('.pf-home__subtitle', { duration: 0.8, opacity: 1, translateX: 0, delay: 0.3 }, 'f2')
    tl.to('#btnParticipar', { duration: 0.8, opacity: 1, delay: 0.6 }, 'f2')

    // SECCION DE PARTICIPA
    gsap.to('.pf-participate__title.pf-participate__title--legend', { duration: 0.8, opacity: 1, translateY: 0 })
    gsap.to('h2.pf-participate__title', {
        duration: 0.8, scrollTrigger: {
            trigger: 'h2.pf-participate__title', start: 'top bottom-=50px'
        }, opacity: 1, translateX: 0
    })

    let participateElements = gsap.utils.toArray('.pf-participate__image, #btnParticipar2, .pf-meet-pura-malta__video-container, .pf-meet-pura-malta__button')
    participateElements.forEach((elm: any) => {
        gsap.to(elm, {
            duration: 0.8, scrollTrigger: {
                trigger: elm, start: 'top bottom-=50px'
            }, opacity: 1
        })
    })

    gsap.to('.pf-participate__list', {
        durantion: 0.8, scrollTrigger: {
            trigger: '.pf-participate__list',
            start: 'top bottom-=50px',
            toggleClass: 'pf-participate__list--active',
            once: true,
        }
    })

    gsap.to('.pf-participate__list-item', {
        durantion: 1, scrollTrigger: {
            trigger: '.pf-participate__list', start: 'top bottom-=50px'
        }, opacity: 1, translateX: 0, stagger: 0.6
    })

    // SECCION FINAL
    let textList = gsap.utils.toArray('.pf-meet-pura-malta__desc, .pf-meet-pura-malta__title')
    textList.forEach((text: any) => {
        gsap.to(text, {
            durantion: 1, scrollTrigger: {
                trigger: text, start: 'top bottom-=50px'
            }, opacity: 1, translateX: 0
        })
    })
}