export const page = async () => {
    validateForm();
    const buttonsAnswerRaw = document.querySelectorAll('.form__input')
    const buttonsAnswer = Array.from( buttonsAnswerRaw )
    const questionCount = document.getElementById('question');
    const formData = new FormData();
    const headerTrivia = document.querySelector('.pfboxTrivia__container--top')
    const pfCode:HTMLElement = document.querySelector("#stock");
    const pfModal:HTMLElement = document.querySelector(".pfModal");
    const boxTransition:HTMLElement = document.querySelector(".cv-transition-block");
    
    const marketingCheck:HTMLInputElement = document.querySelector('#checkMarketing'),
        tycCheck:HTMLInputElement = document.querySelector('#checkTyc');
    let counter = 1


    buttonsAnswer.length > 0 && buttonsAnswer.map( btnAnswer => {

        btnAnswer.addEventListener( 'click' , e => {

            const parentBox = btnAnswer.closest('.pfboxTrivia__container--body.active')
            const nextParentBox = parentBox.nextElementSibling
            const progressSingleRaw = document.querySelectorAll('.boxline__single.done')
            const progressSinglesLast = progressSingleRaw[progressSingleRaw.length - 1] || document.querySelector('.boxline__single')
            // const progressSingleRaw = document.querySelectorAll('.boxline__single')
            const selectBtn = btnAnswer as HTMLInputElement,
                inputName:HTMLInputElement = document.querySelector('#firstname'),
                question:HTMLElement = parentBox.querySelector('.titleQuestion');
            // console.log(progressSinglesLast);

            // Update final data
            formData.set('friendName', inputName.value);
            // Pregunta - Respusta
            let textTyc;
            let textMarketing;
            if(tycCheck.checked){
                textTyc = 'true';
            }else{
                textTyc = 'false';
            }
            if(marketingCheck.checked){
                textMarketing = 'true';
            }else{
                textMarketing = 'false';
            }
            formData.set(question.dataset.id, selectBtn.dataset.id)
            formData.set('tyc', textTyc);
            formData.set('marketing', textMarketing);

           
            
            if( nextParentBox ) {

                // nextParentBox.classList.add('animate__slow')

                boxTransition.classList.add('cv-transition-block--activated')

                setTimeout(() =>{
                    boxTransition.classList.add('cv-transition-block--hidding')
                },1000)

                setTimeout(() =>{
                    progressSinglesLast.classList.contains('done') ?
                    progressSinglesLast.nextElementSibling.classList.add('done') :
                    progressSinglesLast.classList.add('done')
                    nextParentBox.classList.add('active')

                    nextParentBox.classList.add('animate__animated')
                    nextParentBox.classList.add('animate__fadeIn')
                },1070)

                setTimeout(() =>{
                    boxTransition.classList.remove('cv-transition-block--hidding')
                    boxTransition.classList.remove('cv-transition-block--activated')
                },2000)
                // progressSinglesLast.nextElementSibling.classList.add('done')

                counter++

            } else {
                headerTrivia.classList.add('hidden')
                // Code to the last step

                fetch('/poker/test-de-la-amistad/save', {
                    method: "POST",
                    body: formData,
                    // headers: {
                    //     "Content-Type": "multipart/form-data",
                    // },
                })
                .then(response => response.json())
                .then((json) => {
                    console.log(json.code);

                    if (json.code == 1) { // Paila
                        pfModal.classList.add('active');
                    }
                    //Agotado existencias
                    if (json.code == 2) {
                        pfCode.classList.add('active');
                    }
                    console.log("Response 200, do nothing", json)
                    if(json.moreCodes){
                        window.location.href = json.redirect;
                    }else{
                        pfCode.classList.add('active');
                        const btnModal:HTMLElement = pfCode.querySelector('.pfbtnForm');
                        btnModal.setAttribute('href', json.redirect)
                    }
                    console.log(json)
                })
                .catch((err: any) => {
                    // console.log(err)
                    console.log("error", err)
                });

            }

            setTimeout(() =>{
                parentBox.classList.remove('active')
            },950)
            questionCount.innerHTML = counter.toString();

        } )

    } )

}
function validateForm(){
    const btnForm = document.querySelector('.pfbtnForm'),
        inputForm:HTMLInputElement = document.querySelector('#firstname'),
        labelError = document.querySelector('#errorMessage'),
        labelErrorTyc = document.querySelector('#errorTyc'),
        checkMarketing:HTMLInputElement = document.querySelector('#checkMarketing'),
        checkTyc:HTMLInputElement = document.querySelector('#checkTyc');
        
    const pfboxForm = document.querySelector('.pfboxForm'),
        pfboxTrivia = document.querySelector('.pfboxTrivia');
    btnForm.addEventListener( 'click' , e => {
        let input = inputForm.value;
        let valid;
        const regEx = new RegExp("^[a-zA-ZÀ-ÿ\u00F1\u00D1]+$");
        if(input == " " || input == null || input == ""){
            labelError.innerHTML = 'Este campo es obligatorio'
            labelError.classList.add('active')
            valid = false;
        }else if(!regEx.test(input)){
            labelError.innerHTML = 'No se permiten números'
            labelError.classList.add('active')
            valid = false;
        }
        else if(!checkTyc.checked){
            labelErrorTyc.innerHTML = 'Debe aceptar términos y condiciones y políticas de protección de datos personales'
            labelErrorTyc.classList.add('active')
            valid = false;            
        }
        else{
            valid = true;
            labelErrorTyc.classList.remove('active')
            labelError.classList.remove('active')
        }
        if(valid){
            pfboxForm.classList.add('hideDisplay')
            pfboxTrivia.classList.add('active')
        }

    })

}
