export const routes = [
    {
      name: 'landing Poker',
      path: ['/', '/index.html', '/test-de-la-amistad'],
      component: 'carouselSteps'
    },
    {
      name: 'landing Trivia Poker',
      path: ['/test-de-la-amistad/preguntas', '/test-de-la-amistad/trivia'],
      page: 'trivia'
    },
    {
      name: 'landing Trivia Poker',
      path: ['/test-de-la-amistad/chanda', '/test-de-la-amistad/parchada', '/test-de-la-amistad/tibia', '/test-de-la-amistad/toxica', '/test-de-la-amistad/calidosa'],
      component: 'htmltobase64'
    },
    {
      name: 'Cerveza por Amistad Participa',
      path: ['/pura-malta/cerveza-por-amistad/participa', '/pura-malta/cerveza-por-amistad/participa/'],
      page: 'user-zone'
    },
    {
      name: 'Cerveza por Amistad Landing',
      path: ['/pura-malta/cerveza-por-amistad', '/pura-malta/cerveza-por-amistad/'],
      page: 'landing'
    },
    {
      name: 'Cerveza por Amistad 2',
      path: ['/pura-malta', '/pura-malta/', '/cerveza-poker-pura-malta', '/cerveza-poker-pura-malta/'],
      page: 'detail'
    },
    {
      name: 'Cerveza por Amistad Galeria',
      path: ['/test.html', '/pura-malta/parche-poker', '/pura-malta/parche-poker/'],
      page: 'gallery'
    }
]
