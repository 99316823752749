// Import Swiper
import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'


Swiper.use([Navigation, Pagination]);


export const component = async () => {

  const rawCarouselSteps  = document.querySelectorAll('.pfCarousel--steps')
  const carouselSteps:Element[] = Array.from( rawCarouselSteps )

  if( carouselSteps.length > 0 ) {

    const carouselStepSwiper = new Swiper( '.pfCarousel--steps', {
      slidesPerView: 1,
      spaceBetween: 20,
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        // 320: {
        //   slidesPerView: 2,
        //   spaceBetween: 20
        // },
        991: {
          slidesPerView: 3,
          spaceBetween: 40,
          pagination: false
        }
      }
    } )

  }

}
