// import dama from "/modules/dama.js";
// import apiDrupal from "./api.js";
// const dm = new dama();
import * as AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css';

import "lazysizes";

import Modal from '../components/modal';

import { apiPuraMalta } from "../services/api";
const api = new apiPuraMalta()

// const api = new apiDrupal();
export const page = async () => {
    /* ON LOAD */

    loadPuraMalta();
    document.addEventListener('DOMContentLoaded', e => {
        console.log('Dama: Desarrollado por 150%')
        validateAOS();



        const btnagedateYes: HTMLElement = document.querySelector('.age_gate_yes_custom')
        btnagedateYes && btnagedateYes.addEventListener('click', e => {
            e.preventDefault()
            validateAOS()
        })

        //copy cod rappi page result
        const btnCopy = document.querySelector('.pfboxRappi__btnCopy');

        if (btnCopy) {
            // console.log('exists');
            btnCopy.addEventListener("click", e => {
                e.preventDefault()
                const textcopy = document.querySelector('.pfboxRappi__code');
                const range = document.createRange();
                const selection = window.getSelection();
                range.selectNodeContents(textcopy);
                selection.removeAllRanges();
                selection.addRange(range);
                const successful = document.execCommand('copy');
                if (successful) {
                    console.log('copi');

                } else {
                    console.log('nocopi');

                }
                window.getSelection().removeAllRanges()

            })
        } else {
            // console.log('none');

        }
        function validateAOS() {
            const bodyAgedate: HTMLElement = document.querySelector('.entity-node-canonical.path-node')
            if (bodyAgedate) {
                const classes = bodyAgedate.classList;
                setTimeout(() => {
                    if (classes.contains('agegate-visible')) {
                        setTimeout(() => {
                            if (!classes.contains('agegate-visible')) {
                                AOS.init();
                            }
                        }, 300)
                    }
                    else {
                        AOS.init();
                    }
                }, 100)
            }
        }
    })
}

// CLEAR SEO
export const fixedSEO = () => {
    let h2Headers = document.querySelectorAll('.c-header h2, #block-piedepagina, #block-abpacofooter-menu, #block-abpacoheader-menu')
    if (h2Headers.length > 0) Array.from(h2Headers).forEach(elm => elm.remove())
}

const loadPuraMalta = () => {
    getCodes()
    new Modal('#thanksModal', {
        onClose: () => {
            window.location.href = '/pura-malta/cerveza-por-amistad'
        }
    })
    new Modal('#noCodesModal')
    new Modal('#popupError')
    new Modal('#alreadyParticipatedModal', {
        onClose: () => {
            window.location.href = '/pura-malta/cerveza-por-amistad'
        }
    })
}

const getCodes = async () => {
    let refCodeModal = document.querySelector('#noCodesModal')
    let refUserName: HTMLElement = document.querySelector('#userName')
    try {
        const response: any = await api.checkCodes()
        if (refCodeModal && response.error) {
            refCodeModal.classList.remove('pf-modal--hide');
            let label = 'Usuario aún no ha ingresado';
            if(response.email) {
                const email2 = window.CryptoJS.SHA256(response.email);
                label = email2.words.toString();
            }
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'trackEvent',
                'eventCategory': 'Cerveza Poker', // Categoría del evento (String). Requerido.
                'eventAction': 'No Codigos Cerveza por Amistad', // Acción o subcategoría del evento (String). Requerido.
                'eventLabel': label, // Etiqueta de descripción del evento (String). Requerido.
            });
        }
        if (refUserName) refUserName.innerHTML = `${response.user[0] && response.user[0].split(' ')[0]}`
    } catch (error) {
        console.log(error);
    }
}
