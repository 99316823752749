/** MODAL **/

// INTERFACES
export interface ModalInterface {
    modalSelector: string;
    modalOptions?: ModalOptions;
}

export interface ModalOptions {
    activator?: string;
    mainModalClass?: string;
    params?: Array<{ dataRef: string, attr?: string }>;
    youtubeVideo?: { wrapperId: string, videoId?: string };
    onOpen?(e: MouseEvent): void;
    onClose?(e: MouseEvent): void;
}

export default class Modal implements ModalInterface {
    modalSelector: string;
    modalOptions: ModalOptions = { mainModalClass: 'pf-modal' };
    playerModal: any;

    constructor(modalSelector: string, modalOptions?: ModalOptions) {
        this.modalSelector = modalSelector;
        this.modalOptions = { ...this.modalOptions, ...modalOptions };
        if (modalOptions && modalOptions.onOpen) this.onOpen = modalOptions.onOpen;
        if (modalOptions && modalOptions.onClose) this.onClose = modalOptions.onClose;

        this.setOpenModal()
        this.setCloseModal()
        this.initYoutubeIframe()
    }

    initYoutubeIframe(): void {
        if (this.modalOptions.youtubeVideo && this.modalOptions.youtubeVideo.wrapperId) {
            let refWrapper = document.querySelector(`#${this.modalOptions.youtubeVideo.wrapperId}`)
            if (refWrapper) {
                refWrapper.insertAdjacentHTML('beforeend', '<iframe width="560" height="315" src="" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>')
                let iframe = refWrapper.querySelector('iframe')
                this.playerModal = { iframe, target: refWrapper, YTIframe: false }
            }
        }
    }

    setOpenModal(): void {
        if (this.modalOptions.activator) {
            let refDOMButton: NodeListOf<HTMLElement> = document.querySelectorAll(this.modalOptions.activator);
            if (refDOMButton.length > 0) {
                for (let i = 0; i < refDOMButton.length; i++) {
                    refDOMButton[i].addEventListener('click', (e) => this.openModal(e))
                }
            }
        }

    }

    openModal(e: any): void {
        document.querySelector(this.modalSelector).classList.remove(`${this.modalOptions.mainModalClass}--hide`)
        this.sendParams(e.target as HTMLElement)
        this.toggleLoader()
        this.onOpen(e)
        if (e.target.dataset.videoLink) this.setVideoId(e.target.dataset.videoLink)
    }

    sendParams(target?: HTMLElement): void {
        if (this.modalOptions.params) {
            this.modalOptions.params.forEach(param => {
                let ref = document.querySelector(`${this.modalSelector} [${param.dataRef}]`)
                if (param.attr && ref) {
                    ref.setAttribute(param.attr, target ? target.getAttribute(param.dataRef) : "")
                }
                else if (ref) {
                    ref.innerHTML = target ? target.getAttribute(param.dataRef) : ""
                }
            })
        }
    }

    setCloseModal(): void {
        let refDOMModal: HTMLElement = document.querySelector(this.modalSelector);
        if (refDOMModal) {
            refDOMModal.addEventListener('click', (e) => {
                let currentTarget = e.target as HTMLElement;
                if (currentTarget.classList.contains(`${this.modalOptions.mainModalClass}`) ||
                    currentTarget.classList.contains(`${this.modalOptions.mainModalClass}__close`) ||
                    currentTarget.classList.contains(`${this.modalOptions.mainModalClass}__close-aditional`)) {
                    refDOMModal.classList.add(`${this.modalOptions.mainModalClass}--hide`)
                    this.sendParams()
                    this.toggleLoader()
                    this.onClose(e)
                    if (this.playerModal) this.playerModal.iframe.src = ''
                }
            })
        }
    }

    toggleLoader(): void {
        let refLoader: HTMLElement = document.querySelector(`${this.modalSelector} .loader`)
        refLoader && refLoader.classList.toggle('loader--hidden')
    }

    setVideoId(videoId: string): void {
        this.modalOptions.youtubeVideo.videoId = videoId
        if (this.playerModal.iframe) this.playerModal.iframe.src = `https://www.youtube.com/embed/${videoId}?autoplay=1`

    }

    onOpen(e: MouseEvent): void { }

    onClose(e: MouseEvent): void { }
}

