import * as Bouncer from "formbouncerjs";
import { emails } from "../config/constants";
import { apiPuraMalta } from "../services/api";
const api = new apiPuraMalta()

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

declare global {
    interface Window {
        dataLayer: any;
        getUserTapit(): any
        CryptoJS: any;
    }
}

export const page = async () => {
    checkAge()
    formRegister()
    getFormProgress()
    setHandlers()
    loadAnimation()
}

const checkAge = () => {
    const agegateValue = localStorage.getItem('ageGateValidate')
    if (!agegateValue) window.location.href = '/pura-malta/cerveza-por-amistad'
}

let formBouncer: any;

const formRegister = (): void => {
    // @ts-ignore
    formBouncer = new Bouncer('#formUser', {
        patterns: {
            email: /^[a-z0-9!#$%&'*/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
        },
        customValidations: {
            emailVerified: (field: any) => {
                let array_email = field.value.split("@");
                const res = emails.find((m) => m === array_email[1]);
                return res === undefined ? false : true;
            },
            onlyNumber: ({ name, value }: any) => {
                if (name == 'idn') {
                    const pattern = new RegExp("^[0-9]+$", "i");
                    if (!pattern.test(value)) return true;
                }
            },
            maxAndMinId: ({ name, value }: any) => {
                if (name == 'idn') {
                    if (value.length < 7 || value.length > 10) return true;
                }
            }
        },
        messages: {
            missingValue: {
                checkbox: "Debe aceptar términos y condiciones y políticas de protección de datos personales",
                default: "Campo obligatorio",
            },
            emailVerified: "Por favor ingrese un email válido",
            onlyNumber: 'Solo se permiten número',
            maxAndMinId: 'Solo se permiten entre 7 a 10 dígitos',
            patternMismatch: {
                email: "Por favor ingrese un email válido",
            },
        },
    });
};

const setHandlers = () => {
    let errorModal: HTMLButtonElement = document.querySelector('#popupError')
    let thanksModal: HTMLButtonElement = document.querySelector('#thanksModal')
    let btnSendImage: HTMLButtonElement = document.querySelector('#sendImage')
    let formUploadImage: HTMLFormElement = document.querySelector('#formUploadImage')

    // ESTABLECE LA FUNCION EN EL BOTON DE BORRAR PARA REGRESAR A LA ANTERIOR SECCION
    let refCheckBtn = document.querySelector('.pf-check-file__button-check')
    refCheckBtn && refCheckBtn.addEventListener('click', ({ currentTarget }: any) => {
        currentTarget.classList.toggle('pf-check-file__button-check--active')
        if (currentTarget.classList.contains('pf-check-file__button-check--active')) btnSendImage.removeAttribute('disabled')
        else btnSendImage.setAttribute('disabled', '')
    })

    // ESTABLECE LA FUNCION EN EL BOTON DE BORRAR PARA REGRESAR A LA ANTERIOR SECCION
    let refDeleteBtn = document.querySelector('.pf-check-file__button-delete')
    refDeleteBtn && refDeleteBtn.addEventListener('click', () => {
        changeSection('second')
        refCheckBtn.classList.remove('pf-check-file__button-check--active')
        btnSendImage.setAttribute('disabled', '')
        formUploadImage.reset()
    })

    // ESTABLECE LA FUNCION EN EL BOTON SIGUIENTE PARA ENVIAR LA DATA Y CAMBIAR DE SECCION
    let loader: HTMLElement = document.querySelector('.pf-overlay-loader')
    let formUser: HTMLFormElement = document.querySelector('#formUser')
    let formUserBtn: HTMLButtonElement = document.querySelector('#uploadPhoto')
    formUserBtn && formUserBtn.addEventListener('click', (e: any) => {
        e.preventDefault();
        loader && loader.classList.remove('pf-overlay-loader--hide')

        let areValid = formBouncer.validateAll(formUser);
        if (areValid.length > 0) return loader && loader.classList.add('pf-overlay-loader--hide')
        if (areValid.length == 0) {
            const formData: any = new FormData(formUser);
            api.sendUserData(formData)
                .then((response: any) => {
                    if (!response.error) changeSection('second')
                    loader && loader.classList.add('pf-overlay-loader--hide')
                })
                .catch((error: any) => {
                    loader && loader.classList.add('pf-overlay-loader--hide')
                })
        }
    })

    // ESTABLECE LA FUNCION EN EL BOTON QUE SUBIRA LA IMAGEN/VIDEO
    btnSendImage && btnSendImage.addEventListener('click', ({ currentTarget }: any) => {
        let maxSize = parseInt(document.querySelector('#maxSizeImage').innerHTML) ?? 10;
        let refInputImage: HTMLInputElement = document.querySelector('#inputImage')
        loader && loader.classList.remove('pf-overlay-loader--hide')
        if (refInputImage.files[0].size > (maxSize * 1000000)) {
            if (!errorModal) return;
            errorModal.classList.remove('pf-modal--hide')
            const modalMessage = errorModal.querySelector('.pf-custom-message-modal')
            if (modalMessage) modalMessage.innerHTML = `El tamaño del archivo es mayor a ${maxSize}MB`
            loader && loader.classList.add('pf-overlay-loader--hide')
            return
        }
        const formData = new FormData()
        formData.append('file', refInputImage.files[0])
        api.sendImage(formData)
            .then((response: any) => {
                if (response.error) {
                    if (!errorModal) return;
                    errorModal.classList.remove('pf-modal--hide')
                    const modalMessage = errorModal.querySelector('.pf-custom-message-modal')
                    if (modalMessage) modalMessage.innerHTML = response.message
                }
                else {
                    thanksModal && thanksModal.classList.remove('pf-modal--hide');
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'trackEvent',
                        'eventCategory': 'Cerveza Poker', // Categoría del evento (String). Requerido.
                        'eventAction': 'Cerveza por Amistad', // Acción o subcategoría del evento (String). Requerido.
                        'eventLabel': 'Registro Completo', // Etiqueta de descripción del evento (String). Requerido.
                    });

                }
                loader && loader.classList.add('pf-overlay-loader--hide')
            })
            .catch((error: any) => {
                loader && loader.classList.add('pf-overlay-loader--hide')
            })
    })

    // ESTABLECE LA FUNCION PARA CARGAR LA IMAGEN PREVIA DESPUES DE SER SELECCIONADA
    loadImage()
}

// CARGA LA IMAGEN EN LA SECCION PARA CONFIRMAR
const loadImage = () => {
    let refInputFile: HTMLInputElement = document.querySelector('.pf-upload-file__input')
    let refContainerImage: HTMLImageElement = document.querySelector('.pf-check-file__image');
    let refNameImage: HTMLElement = document.querySelector('.pf-check-file__name');
    let refSizeImage: HTMLElement = document.querySelector('.pf-check-file__size');

    refInputFile && refInputFile.addEventListener('change', ({ target }: any) => {
        if (target.files && target.files.length > 0) {
            const [file] = target.files
            const fileType = file.type.includes('image') ? 'img' : 'video'

            if (refContainerImage) createElement(fileType, refContainerImage).src = URL.createObjectURL(file)
            if (refNameImage) refNameImage.innerHTML = file.name
            if (refSizeImage) refSizeImage.innerHTML = convertBytes(file.size)
            changeSection('third')
        }
    })
}

// CREA VIDEO O IMAGEN
const createElement = (typeElement: string, wrapper: Element): any => {
    const nwElement: any = document.createElement(typeElement)
    wrapper.innerHTML = '';
    wrapper.insertAdjacentElement('beforeend', nwElement)
    return nwElement;
}

// CONVIERTE LOS BYTES A MEGASBYTES O KILOBYTES
const convertBytes = (bytes: number) => {
    if (bytes > 1_000_000) {
        let kilobytes = bytes / 1024
        return `${Math.round(kilobytes / 1024)} MB`
    }
    return `${Math.round(bytes / 1024)} KB`
}

// CAMBIA DE SECCION
let tabContainer = document.querySelector('#userTab')
const changeSection = (sectionName: string) => {
    if (tabContainer) {
        let classTabElements = { tab: 'pf-tab__nav-item', article: 'pf-tab__article-item' }

        let activeTab = tabContainer.querySelector(`.${classTabElements.tab}--active`)
        let activeArticle = tabContainer.querySelector(`.${classTabElements.article}--active`)
        let nwTab = document.querySelector(`.${classTabElements.tab}[data-tab='${sectionName}']`)
        let nwArticle = document.querySelector(`.${classTabElements.article}[data-article='${sectionName}']`)

        if (activeTab) {
            activeTab.classList.remove(`${classTabElements.tab}--active`)
            nwTab && nwTab.classList.add(`${classTabElements.tab}--active`)
        }
        if (activeArticle) {
            activeArticle.classList.remove(`${classTabElements.article}--active`)
            nwArticle && nwArticle.classList.add(`${classTabElements.article}--active`)
        }
    }
}

// VERIFICA EN QUE PASO SE QUEDO EL USUARIO Y LO LLEVA A LA SECCION
const getFormProgress = async () => {
    let refParticipateModal = document.querySelector('#alreadyParticipatedModal')
    try {
        const response: any = await api.checkUploadUserData()
        const progress = !response.form ? 1 : response.form && !response.file ? 2 : 3
        switch (progress) {
            case 1:
                break;
            case 2:
                changeSection('second')
                break;
            case 3:
                refParticipateModal && refParticipateModal.classList.remove('pf-modal--hide')
                const email2 = window.CryptoJS.SHA256(response.email);
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'trackEvent',
                    'eventCategory': 'Cerveza Poker', // Categoría del evento (String). Requerido.
                    'eventAction': 'Ya Participaste Cerveza por Amistad', // Acción o subcategoría del evento (String). Requerido.
                    'eventLabel': email2.words.toString(), // Etiqueta de descripción del evento (String). Requerido.
                });
                break;
        }
    } catch (error) {
    }
}

const loadAnimation = () => {
    const tl = gsap.timeline()
    tl.to('.pf-user-zone__title', { duration: 0.5, opacity: 1 })
    tl.to('.pf-tab__nav-item', { duration: 0.8, opacity: 1, stagger: 0.3 })
    tl.to('#formUser .js-form-item, #uploadPhoto', { duration: 0.8, opacity: 1, translateX: 0, stagger: 0.3 })
}
