import Petition from "../../../modules/petition";

// /**
//  * Use Petition
//  * 
//  * You must use pet.get(url) or pet.post(url, data) 
//  */
const pet = new Petition()

export class apiPuraMalta {
    welcome() {
        return 'welcome to ApiService';
    }
    sendUserData(data: any) {
        return pet.postFD('/co-poker-puramalta-amistad/send/data', data)
    }
    sendImage(data: any) {
        return pet.postFD('/co-poker-puramalta-amistad/save/file', data)
    }
    checkCodes() {
        return pet.get('/co-poker-puramalta-amistad/check/codes')
    }
    checkUploadUserData() {
        return pet.get('/co-poker-puramalta-amistad/check/file')
    }
    getFilesGallery() {
        return pet.get('/read/data/files')
    }
}