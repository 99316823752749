import * as htmlToImage from 'html-to-image';
import { toJpeg } from 'html-to-image';
// @ts-ignore
import axios from 'axios';

export const component = async () => {
    document.addEventListener('DOMContentLoaded', e => {
        const validExport = document.querySelector('#notConvertImg');
        if (!validExport) {
            saveimgBase64();
        }
    });

}
function saveimgBase64() {
    //HTML TO JPG   
    const btnsSocial = document.querySelectorAll('.pfboxSocial__content--single'),
        boxExport = document.getElementById('pfboxExport'),
        urlSearch = window.location.search;

    let srcUrl = urlSearch.substring(4)

    setTimeout(() => {
        if (btnsSocial) {
            htmlToImage.toJpeg(boxExport, {
                quality: 0.75,
                cacheBust: true,
                width: boxExport.offsetWidth,
                height: boxExport.offsetHeight,
                style: {
                    minWidth: `${boxExport.offsetWidth}px`,
                    width: `${boxExport.offsetWidth}px`,
                    maxWidth: `${boxExport.offsetWidth}px`,
                    minHeight: `${boxExport.offsetHeight}px`,
                    height: `${boxExport.offsetHeight}px`,
                    maxHeight: `${boxExport.offsetHeight}px`,
                    margin: 'unset',
                    position: 'relative',
                    right: 'initial',
                    bottom: 'initial',
                    opacity: '1'
                }
            })
                .then(function (dataUrl: any) {
                    var img = new Image();
                    img.src = dataUrl;
                    console.log(img.src);
                    // document.body.appendChild(img);
                    const formData1 = new FormData();
                    formData1.append('imgShare', img.src);
                    formData1.append('src', srcUrl);

                    axios.post('/poker/test-de-la-amistad/saveImg', formData1)
                        .then(function (response: any) {
                            console.log(response);
                            let storageReload;
                            storageReload = localStorage.getItem('reload');
                            if (storageReload == 'ok') {
                                console.log(storageReload);
                            } else {
                                localStorage.setItem('reload', 'ok')
                                location.reload();
                            }
                        })
                        .catch((err: any) => {
                            console.log("error", err)
                        });
                })
                .catch(function (error: any) {
                    console.error('oops, something went wrong!', error);
                });
        }
    }, 2000)
}