/*** COLLAGE ***/

interface CollageConfig {
    previewContainer: { selector: string, target?: HTMLElement };
    activatorClass?: string;
}

interface SelectedResource {
    source: string,
    target: HTMLImageElement | HTMLVideoElement,
    type?: string,
    previewCreated?: HTMLImageElement | HTMLVideoElement
}
export default class Collage {
    config: CollageConfig;
    isShowPreview: boolean = false;
    mainPreviewClass: string = 'pf-preview-asset';
    selectedCard: SelectedResource;
    sourceContainer: HTMLElement;

    constructor(config: CollageConfig) {
        this.config = config;
        this.config.previewContainer = { ...config.previewContainer, target: document.querySelector(config.previewContainer.selector) }
        this.init()
    }

    init() {
        let { previewContainer } = this.config
        if (previewContainer.target) {
            this.sourceContainer = previewContainer.target.querySelector(`.${this.mainPreviewClass}__asset`)
            this.setOpenPreview()
            this.setClosePreview()
        }
    }

    setOpenPreview(): void {
        let { activatorClass } = this.config
        document.addEventListener('click', (e: any) => {
            if (e.target.closest(activatorClass)) {
                let cardContainer = e.target.closest(activatorClass)
                this.selectedCard = { source: cardContainer.dataset.source, target: cardContainer }
                this.openPreview()
            }
        })
    }

    setClosePreview(): void {
        let { previewContainer } = this.config
        let closeElements = [`${this.mainPreviewClass}`, `${this.mainPreviewClass}__close`]

        previewContainer.target.addEventListener('click', (e) => {
            let currentTarget = e.target as HTMLElement;
            closeElements.forEach((classElement: any) => {
                if (currentTarget.classList.contains(classElement)) {
                    this.closePreview()
                }
            })
        })
    }

    openPreview(): void {
        let { previewContainer } = this.config
        let { source, target } = this.selectedCard
        previewContainer.target.classList.remove(`${this.mainPreviewClass}--hide`)
        this.isShowPreview = true
        this.setSource(source)
    }

    closePreview(): void {
        let { previewContainer } = this.config
        let { type, previewCreated } = this.selectedCard
        previewContainer.target.classList.add(`${this.mainPreviewClass}--hide`)
        this.isShowPreview = false
        if (type == 'video') {
            let tagVideo = previewCreated as HTMLVideoElement
            tagVideo.pause()
        }
    }

    cleanSources(): void {
        this.sourceContainer.innerHTML = ''
    }

    setSource(source: string): void {
        this.cleanSources()
        let nwTagName = source.match(/.png|.jp(e)?g/gi) ? 'img' : 'video'
        let elementCreated = this.createElement(nwTagName, source)
        this.selectedCard = { ...this.selectedCard, type: nwTagName, previewCreated: elementCreated }
        this.sourceContainer.insertAdjacentElement('beforeend', elementCreated)
    }

    createElement(typeElement: string, source: string): HTMLImageElement | HTMLVideoElement {
        let nwElement: HTMLImageElement | HTMLVideoElement;
        switch (typeElement) {
            case 'img':
                nwElement = document.createElement('img')
                nwElement.src = source
                return nwElement

            case 'video':
                let sourceVideo = document.createElement('source')
                sourceVideo.src = source
                nwElement = document.createElement('video')
                nwElement.autoplay = true
                nwElement.appendChild(sourceVideo)
                return nwElement
        }
    }
}
