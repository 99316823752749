// Modules
import SplitText from "../components/splitText";
import Collage from "../components/collage";
import Floating from "../components/floating";

// Services
import { apiPuraMalta } from "../services/api";
const api = new apiPuraMalta()

// Libraries
import Swiper, { Pagination } from 'swiper';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const page = async () => {
    // Show loader
    let loader: HTMLElement = document.querySelector('.pf-overlay-loader')
    if (localStorage.getItem('ageGateValidate')) loader.classList.remove('pf-overlay-loader--hide')

    setInitialStates()
    try {
        // Get data from api and create slides
        const response = await api.getFilesGallery()
        await setGallerySwiper(response)

        // Hide loader
        loader.classList.add('pf-overlay-loader--hide')

        let goToNextSlide = document.querySelector('.pf-gallery-navigation__button')
        const gallerySwiper: any = new Swiper('#gallerySwiper', {
            modules: [Pagination],
            spaceBetween: 30,
            loop: true,
            watchOverflow: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: function (index: number, className: string) {
                    return '<span class="' + className + '">' + (index + 1) + "</span>";
                },
            }
        })

        goToNextSlide && goToNextSlide.addEventListener('click', () => gallerySwiper.slideNext())
        new Collage({ previewContainer: { selector: '#previewPopup' }, activatorClass: '.pf-card-gallery--asset' })

        if (!localStorage.getItem('ageGateValidate')) return ObserveAgegate()
        loadAnimation()
    } catch (error) { console.log(error); }
}

// CREA LOS SLIDES PARA EL SWIPER
const setGallerySwiper = (data: any) => {
    return new Promise((resolve, reject) => {
        let separator = 4
        let gallerySwiper = document.querySelector('#gallerySwiper .swiper-wrapper')
        let imagesMark = document.querySelectorAll('.pf-collage__item--hide')

        for (let i = 0; i < data.length / separator; i++) {
            let swiperSlide = document.createElement('div')
            let collage = createCollage()

            data.slice(i * separator, (i * separator) + separator).forEach((item: any) => collage.insertAdjacentHTML('beforeend', createCardCollage(item)))

            Array.from(imagesMark).forEach((mark: any) => {
                let nwImageMark = mark.cloneNode(true)
                nwImageMark.classList.remove('pf-collage__item--hide')
                collage.insertAdjacentElement('beforeend', nwImageMark)
            })

            swiperSlide.classList.add('swiper-slide')
            swiperSlide.appendChild(collage)
            gallerySwiper.appendChild(swiperSlide)
        }

        setTimeout(() => resolve({ message: 'Galeria creada' }), 500)
    })
}

// CREA EL COLLAGE
const createCollage = () => {
    let collageContainer = document.createElement('section')
    collageContainer.classList.add('pf-collage')
    return collageContainer
}

// CREA LAS TARJETAS PARA LA GALLERIA
const createCardCollage = (infoCard: any) => {
    const fileType = infoCard.ext && !infoCard.ext.match(/png|jp(e)?g/gi) ? 'video' : 'image';
    const fileTag = fileType == 'video' ?
        `<video class="pf-card-gallery__image lazyload" data-src="${infoCard.file}"></video>` :
        `<img class="pf-card-gallery__image lazyload" data-src="${infoCard.file}" alt="" title=""/>`

    return `<div class="pf-collage__item">
                <div class="pf-card-gallery pf-card-gallery--asset ${fileType == 'video' ? 'pf-card-gallery--video' : ''}" data-source="${infoCard.file}">
                    ${fileTag}
                    <article class="pf-card-gallery__desc">
                        <p>${infoCard.name}</p>
                    </article>
                </div>
            </div>`
}

// ANIMA EL CONTENIDO DE LA PAGINA SOLO SI SE HA ACEPTADO EL AGEGATE
const ObserveAgegate = () => {
    let target = document.body;
    let config = { attributes: true, childList: true, characterData: true };
    let mObserver = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation: any) {
            if (mutation.type == 'attributes' && !mutation.target.classList.contains('agegate-visible')) {
                loadAnimation()
                mObserver.disconnect()
            }
        });
    });
    mObserver.observe(target, config);
}

// CARGA ANIMACIONES
const setInitialStates = () => {
    // Spliting Titles
    new SplitText('.pf-gallery__title')

    // Set Initial Value
    gsap.set('.pf-gallery__desc', { opacity: 0, translateY: 20 })
    gsap.set('.pf-gallery__button, .pf-gallery-navigation__button', { opacity: 0 })

    new Floating('.pf-meet-pura-malta__bottle')
}

// CARGA ANIMACIONES
const loadAnimation = () => {
    // Vars
    const tl = gsap.timeline()
    let cards = gsap.utils.toArray('.pf-card-gallery')

    // Animating
    tl.to('.pf-gallery__desc', { duration: 0.8, opacity: 1, translateY: 0 }, 't1')
    tl.to('.pf-gallery__button', { duration: 0.8, opacity: 1, translateY: 0, delay: 0.4 }, 't1')

    cards.forEach((card: any) => {
        gsap.to(card, {
            scrollTrigger: { trigger: card, start: 'top bottom-=50px' },
            duration: 0.5, opacity: 1, translateY: 0, stagger: 0.3,
        })
    })

    gsap.to('.pf-gallery-navigation__button', {
        scrollTrigger: { trigger: '.pf-gallery-navigation__button', start: 'top bottom-=50px' },
        duration: 0.8, opacity: 1
    })
}